import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import EquipmentComponent from "../components/equipment/equipment.component"

const Equipment = () => {
  return (
    <Layout>
      <EquipmentComponent />
    </Layout>
  )
}

export default Equipment

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
